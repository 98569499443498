<!--
 * @Author: tongteng
 * @Date: 2023-03-22 15:51:04
 * @LastEditors: tongteng
 * @LastEditTime: 2023-05-18 15:28:53
 * @Description: SKU APK采集
-->
<template>
  <div>
    <!-- nav bar -->
    <nav-bar v-if="showBackBtn" @onBack="handleBack" />
    <div v-if="isClose">
      <div v-if="validateParams" class="sku-apk-task">
        <h3>{{ needObscure ? OBSCURE_NAME : skuTaskName }}</h3>
        <div>{{ effectiveTime }}</div>
        <div class="sku-apk-task-upload">
          <div class="sku-apk-task-upload-file">
            <van-button
              v-if="taskDetail"
              class="task-btn"
              v-bind="btnConfig"
              :loading="launchLoading"
              @click="launchTask"
              :disabled="isBtnDisabled"
            >开始任务</van-button
            >
            <template v-if="districtInfo">
              <div>区/县要求：</div>
              <div>{{ districtInfo }}</div>
            </template>
            <div class="category" v-if="groceryCategory2Name">{{ groceryCategory2Name }}</div>
            <div class="gap-top">
              <image-collection
                v-if="uploadOptions"
                ref="skuImg"
                :uploadOptions="uploadOptions"
                :accept="accept"
                :task-id="urlParams.userTaskId"
                :job-id="urlParams.jobId"
                :check-repeat="true"
                :is-check-width="false"
                :upload-text="'点击添加文件'"
                :is-check-time="true"
                :job-start-time="jobStartTime"
                :check-file="checkFileCity"
                :isDisabled="isBtnDisabled"
                is-upload-file
                @file-change="onFileChange"
              />
              <div v-else>{{ uploadTips }}</div>
            </div>
          </div>
        </div>
        <div class="submit">
          <van-button
            class="sku-apk-task-submit"
            :loading="loading"
            loading-text="提交中..."
            @click="handleSubmit"
            :disabled="isBtnDisabled"
          >
            点我提交!
          </van-button>
          <div class="feedback">如无法完成任务，请<span class="linkText" @click="clickFeedback">点击此处</span>反馈</div>
        </div>
      </div>
      <paramsError v-else />
    </div>
    <van-empty v-else description />
  </div>
</template>

<script>
import imageCollection from "../ImageCollection/Index";
import NavBar from "../components/nav-bar.vue";
import paramsError from '../components/paramsError.vue'
import {
  getTaskDetails,
  submitCheckCrowdSource
} from "@/apis";
import { formatDate } from "@/tools/date";
import { checkUploadImages, copyText } from "../shared";
import { Toast, Dialog } from "vant";
import submitFileMixins from '../mixins/submit-file'
import backListMixins from "../mixins/back-list";

const OBSCURE_KEYWORD = "脱敏";
const START_UPLOAD_CODE = 1 // 开始上传的状态
export default {
  name: "SkuTask",
  components: {
    imageCollection,
    NavBar,
    paramsError,
  },
  mixins: [backListMixins, submitFileMixins],
  data() {
    return {
      skuTaskName: "",
      effectiveTime: "",
      loading: false,
      isClose: true,
      accept: ".atg,.zip",
      taskStartTime: 0,
      taskEndTime: 0,
      taskDetail: "",
      note: "商品列表长截图（拍照/其他页面无效）",
      title: "",
      OBSCURE_NAME: "录屏事件名称",
      needObscure: false,
      uploadOptions: null,
      uploadTips: "",
      taskType: "",
      jobStartTime: 0,
      deliveryArea: "",
      companyName: "",
      districtInfo: '',
      isShowConfirm: false,
      submitInfo: null,
      submitImgs: null,
      userCode: '',
      launchLoading: false,
      cptCityId: '',
      groceryCategory2Name: '',
      btnConfig: {
        size: "small",
        type: "info",
        round: true,
        loadingText: '复制中...'
      },
      arouseSchema: '',
      crowdSourceTaskCheck: true
    };
  },

  destroyed() {
    document.title = "";
  },
  created() {
    this.checkCrowdSourceTask()
  },
  computed: {
    processText() {
      return `${this.qualifiedNumber || 0}/${this.needNumber || 0}`
    },
    process() {
      if (this.needNumber === 0) {
        return 0;
      } else if(this.qualifiedNumber > this.needNumber) {
        return 100;
      } else {
        return this.qualifiedNumber / this.needNumber * 100;
      }
    },
    isBtnDisabled(){
      // 众包列表&超管，置灰
      if(this.isCrowdSource && this.isSupper) {
        return true;
      }
      // 众包校验不通过，置灰
      if(!this.crowdSourceTaskCheck){
        return true;
      }
      return false;
    },
  },

  methods: {
    /**
     * @description 复制任务信息到剪切板
     */
     launchTask(){
      this.launchLoading = true;
      try {
        copyText(this.taskDetail);
        this.jumpToApp();
      } catch (error) {
        Toast.fail('获取失败，请重试');
      } finally{
        this.launchLoading = false;
      }
    },
    /**
     * @description: 获取当前任务详情
     * @return {*}
     */    
    async getJobDetails() {
      try {
        const { userTaskId, status } = this.urlParams;
        const res = await getTaskDetails({ userTaskId, path: process.env.CHANNEL, status: status ? +status : undefined });
        if (res) {
          const data = res.data || {};
          if (res.code !== 0)
            return Toast.fail(res.msg || "获取任务详情接口返回错误");
          const time = formatDate(
            new Date(data.jobEndTime),
            "yyyy-MM-dd HH:mm:ss"
          );
          if (data.note) {
            this.note = data.note;
          }
          this.needObscure = !!data.isSensitive;
          this.skuTaskName = data.name || "";
          if (data.name) {
            if (data?.name?.indexOf(OBSCURE_KEYWORD) > -1 || this.needObscure) {
              document.title = this.OBSCURE_NAME;
            } else {
              document.title = data.name;
            }
          }
          this.taskDetail = data.taskDetail || "";
          this.arouseSchema = data.arouseSchema || "",
          this.effectiveTime = `有效时间至:${time}`;
          this.taskType = data.taskType;
          this.userCode = data.userCode || ''
          this.jobStartTime = data.jobStartTime || "";
          this.deliveryArea = data.deliveryArea || "";
          this.companyName = data.companyName || "";
          this.districtInfo = data.districtList;
          this.cptCityId = data.cptCityId || "";
          this.groceryCategory2Name = data.groceryCategory2Name || '';
        }
      } catch (err) {
        Toast.fail(err.message || "获取任务详情接口请求错误");
      }
    },
    onFileChange() {
      this.taskStartTime = Date.now();
    },
    /**
     * @description: 校验文件所处的城市
     * @param {*} file 文件
     * @param {*} index 索引
     * @return {*}
     */    
    checkFileCity(file) {
      const cptCityId = file.file.name.split('_')[1]
      if (this.cptCityId && this.cptCityId != cptCityId) {
        file.status = 'failed'
        file.message = '城市错误'
        file.statusCode = START_UPLOAD_CODE
        return {
          valid: false,
          message: '城市错误'
        }
      }

      return {
        valid: true,
        message: ''
      }
    },
    /**
     * @description: 提交文件
     * @return {*}
     */    
    async handleSubmit() {
      if (this.loading) return;
      this.loading = true;
      try {
        const imgs = this.$refs.skuImg.getImagesList();
        const submitInfo = await checkUploadImages(imgs);
        if (submitInfo) {
          const verifyValid = await this.$refs.skuImg.checkFileValid();
          if (!verifyValid.valid) {
            Toast.fail(
              verifyValid.messageType
                ? verifyValid.message
                : `${verifyValid.message}，请重新上传后提交`
            );
            this.loading = false
            return;
          }

          const options = {
            message: this.needObscure ? '确认提交' : `正在提交${this.skuTaskName}, 请再次确认任务城市！`,
            confirmButtonText: '确认提交',
            cancelButtonText: '我在想想',
          }
          await Dialog.confirm(options)
          const checkCsRes = await this.checkCrowdSourceTask()
          if(!checkCsRes) {
            this.loading = false;
            return;
          }
          await this.fetchTaskNew(submitInfo, imgs, 'skuApk')
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    
    clickFeedback() {
      this.$router.push({ path: '/abnormalFeedback'+ location.search});
    },
    // 校验众包任务有效期(非众包不用校验此接口)
    async checkCrowdSourceTask() {
      if(!this.isCrowdSource || this.isSupper){
        return true;
      }
      try {
        const res = await submitCheckCrowdSource({
          userTaskId: this.urlParams.userTaskId
        })
        if (res && res.code === 0) {
          return true
        }else{
          this.crowdSourceTaskCheck = false;
          Toast.fail('此任务已失效，请返回列表页重新刷新')
          return false
        }
      } catch (error) {
        this.crowdSourceTaskCheck = false;
        Toast.fail(error.msg || '众包任务有效期接口校验失败')
        return false
      }
    },
    jumpToApp(){
      if(!this.arouseSchema){
        return;
      }
      location.href = `${this.arouseSchema}?taskinfo=${encodeURIComponent(this.taskDetail)}`;
    }
  },
};
</script>

<style lang="less" scoped>
.sku-apk-task {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  font-size: 16px;
  .sku-apk-task-upload {
    width: calc(100% - 30px);
    padding: 15px;
    margin-top: 25px;
    font-size: 14px;
    background-color: #e2e2e3;
    border-radius: 20px;
    .sku-apk-task-upload-file {
      .task-btn {
        width: 90px;
      }
    }
  }
  .submit {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20%;
    .sku-apk-task-submit {
      width: 100%;
      color: #fff;
      background-color: #007aff;
      border-radius: 16px;
    }
    .feedback {
      padding-top: 10px;
      font-size: 14px;
      text-align: center;
      .linkText{
        font-weight: bold;
        color: #3e7bf3
      }
    }
  }
}
.gap-top {
  margin-top: 15px;
}

.category {
  margin-bottom: 10px;
}
</style>